<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     btn_copy
                     @copy="handleCopy"
                     @reload="handleReload"
                     @edit="handleEdit"
        >
            <div class="pull-right" style="margin-top:4px; ">
                <el-cascader
                    class="mr-3"
                    v-model="search.area"
                    placeholder="请选择地区"
                    size="medium"
                    :options="areas"
                    :props="{checkStrictly:true}"
                    @change="handleAreaChange"
                    style="width: 300px;"
                    clearable
                    filterable>
                </el-cascader>
            </div>
            <div class="pull-right" style="margin-right:10px;margin-top: 4px; ">
                <el-select v-if="search.extra"
                           clearable filterable size="medium"
                           v-model="search.extra['S']"
                           @change="handleChangeSemesterForExtra"
                           placeholder="请选择学期" >
                    <el-option v-for="(x,id) in semester_options" :key="id" :value="id" :label="x.name+' ('+x.start_date+' - '+x.end_date+')'">
                        <span class="d-inline-block" style="width:200px;">{{ x.name }}</span>
                        <span class="text-success">{{ x.start_date }} - {{ x.end_date }}</span>
                    </el-option>
                </el-select>
            </div>
        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual no-submit @submit="handleSubmit"
                     @success="handleSuccess" @cancel="handleCancel">
            <el-button type="success" size="small" class="pull-left" plain slot="footer" @click="unlockMeeting" icon="el-icon-check">
                开启会议
            </el-button>
            <el-button type="danger" size="small" class="pull-left" plain slot="footer" @click="lockMeeting" icon="el-icon-close">
                强制结束会议
            </el-button>
        </edit-dialog>
        <div class="clearfix"></div>
    </section>
</template>

<script>
    import EditDialog from "../components/edit-dialog";
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import SemesterMixin from "@/views/mixins/semester.mixin";

    export default {
        mixins: [FrontMixins,SemesterMixin],
        name: 'admin-course',
        components: {FrontTable, EditDialog},
        created() {
            this.model =  'Admin.' + this.$route.meta.model;
            this.loadArea(2,true);
        },
        computed:{
            can_copy(){
                return true;
            },
        },
        methods: {
            handleSubmit(data) {
                // 如果是编辑记录，并且修改了room_group_id的话，需要提示才能进行修改
                if (data.id ){
                    if (data.room_group_id !== this.single_copy.room_group_id
                        && data.is_closed !== this.single_copy.is_closed ) {
                        this.$notice.error("会议组id 和 会议结束 不可以同时修改");
                        return;
                    }
                    if (data.room_group_id !== this.single_copy.room_group_id) {
                        this.$affirm("修改会议室组的id会导致会议号的改变，请确认是否继续?")
                            .then(()=>{this.doEditSubmit(data);})
                    } else if (data.is_closed === 'YES' && ""+this.single_copy.is_closed === "0") {
                        this.$affirm("课程并没有结束，选择重建将会导致会议号改变，请确认是否继续?")
                            .then(()=>{this.doEditSubmit(data);})
                    } else if ( ""+data.is_closed === '0' && ""+this.single_copy.is_closed === "1") {
                        this.$affirm("课程从结束状态改成非结束状态，请使用重建会议号方式否则无法确定会议号是否有效，请确认是否继续?")
                            .then(()=>{this.doEditSubmit(data);})
                    } else {
                        this.doEditSubmit(data);
                    }
                }  else {
                    this.doEditSubmit(data);
                }
            },
            afterFirstReload(){
                this.loadSemesterListForExtra();
            },
            jumpUpload(){
                this.$router.push('/admin/import-course');
            },
            lockMeeting(){
                this.$api(this.model+'.Lock',this.single,1).then(()=>{
                    this.$notify.success('会议已经结束，并锁定!');
                })
            },
            unlockMeeting(){
                this.$api(this.model+'.Unlock',this.single,1).then(()=>{
                    this.$notify.success('会议已经开放!');
                })
            },
            downloadReport(){
                window.open(this.$apiUrl('Admin.Report.Course.Arrange'));
            }
        }
    }

</script>

<style lang="scss">

</style>
