<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     :btn_add="false"
                     btn_copy
                     @copy="handleCopy"
        >
            <div class="pull-right" style="margin-top:4px; ">
                    <el-cascader
                        class="mr-3"
                        v-model="search.area"
                        placeholder="请选择地区"
                        size="medium"
                        :options="areas"
                        :props="{checkStrictly:true}"
                        @change="handleAreaChange"
                        style="width: 200px;"
                        clearable
                        filterable>
                    </el-cascader>
                    <el-select v-if="search.extra"
                               clearable filterable size="medium"
                               v-model="search.extra['S']"
                               @change="handleChangeSemesterForExtra"
                               placeholder="请选择学期" >
                        <el-option v-for="(x,id) in semester_options" :key="id" :value="id" :label="x.name+' ('+x.start_date+' - '+x.end_date+')'">
                            <span class="d-inline-block" style="width:200px;">{{ x.name }}</span>
                            <span class="text-success">{{ x.start_date }} - {{ x.end_date }}</span>
                        </el-option>
                    </el-select>
                <el-checkbox @change="handleChange" style="margin: 0 10px" v-model="search.use_date_range"
                             value="1"></el-checkbox>
                <el-button size="small" icon="el-icon-arrow-left" :disabled="!search.use_date_range" circle class="mr-2"
                           @click="goDateChangeBack"></el-button>
                <el-date-picker size="small"
                                :picker-options="{'firstDayOfWeek': 1}"
                                @change="handleChange"
                                :disabled="!search.use_date_range"
                                v-model="search.date_range"
                                type="daterange"
                                range-separator="-"
                                start-placeholder="开始"
                                value-format="yyyy-MM-dd"
                                end-placeholder="结束">
                </el-date-picker>
                <el-button size="small" icon="el-icon-arrow-right" :disabled="!search.use_date_range" circle
                           class="ml-2" @click="goDateChangeForward"></el-button>
            </div>
        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual
                     @success="handleSuccess" @cancel="handleCancel">
            <div slot="footer" class="pull-left">
                <el-button size="small" type="success" plain @click="unlockMeeting">开启会议</el-button>
                <el-button size="small" slot="footer" type="danger" plain @click="lockMeeting">锁定会议</el-button>
            </div>
        </edit-dialog>
        <div class="clearfix"></div>
    </section>
</template>

<script>
    import EditDialog from "../components/edit-dialog";
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import SemesterMixin from "@/views/mixins/semester.mixin";

    export default {
        mixins: [FrontMixins,SemesterMixin],
        name: 'admin-schedule',
        components: {FrontTable, EditDialog},
        data() {
            return {
                search: {
                    use_date_range: false,
                    date_range: [],
                },
            }
        },
        created() {
            this.model = 'Admin.' + this.$route.meta.model;
            const date = new Date();
            this.search.date_range = [date.Format('yyyy-MM-dd'), date.Format('yyyy-MM-dd')];
            this.search.use_date_range = false;
            this.loadArea(2,true);
        },
        computed: {
            can_copy() {
                return true;
            },
        },
        methods: {
            jumpUpload() {
                this.$router.push('/admin/import-schedule');
            },
            handleChange() {
                this.reload();
            },
            goDateChangeBack() {
                let first = this.$dateParse(this.search.date_range[0]);
                let second = this.$dateParse(this.search.date_range[1]);

                first.setDate(first.getDate() - 1);
                second.setDate(second.getDate() - 1);
                this.search.date_range = [first.Format('yyyy-MM-dd'), second.Format('yyyy-MM-dd')];
                this.reload();
            },
            goDateChangeForward() {
                let first = this.$dateParse(this.search.date_range[0]);
                let second = this.$dateParse(this.search.date_range[1]);

                first.setDate(first.getDate() + 1);
                second.setDate(second.getDate() + 1);
                this.search.date_range = [first.Format('yyyy-MM-dd'), second.Format('yyyy-MM-dd')];
                this.reload();
            },
            lockMeeting(){
                this.$api(this.model+'.Lock',this.single,1).then(() => {
                    this.$notice.success(('关闭完成!'));
                });
            },
            unlockMeeting(){
                this.$api(this.model+'.Unlock',this.single,1).then(() => {
                    this.$notice.success(('开启完成!'));
                });
            },
            downloadReport(){
                window.open(this.$apiUrl('Admin.Report.Schedule.List'));
            },
            afterFirstReload(){
                this.loadSemesterListForExtra();
            },
        }
    }

</script>

<style lang="scss">

</style>
