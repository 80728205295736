<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
        >
            <div class="pull-right" style="padding-top: 2px;">
                <el-button size="medium" type="warning" class="ml-2 mr-2" disabled
                           icon="el-icon-upload">{{ ('上传排课表') }}
                </el-button>
            </div>
        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>

        <div class="clearfix"></div>
    </section>
</template>

<script>
import EditDialog from "../components/edit-dialog";
import FrontTable from "../components/front-table";
import FrontMixins from "../common/mixins/front-mixins";

export default {
    mixins: [FrontMixins],
    name: 'admin-admin',
    components: {FrontTable, EditDialog},
    created() {
        this.model = 'Admin.' + this.$route.meta.model;
    },
    computed: {
        can_copy() {
            return true;
        },
    },
    methods: {
        jumpUpload() {
            this.$router.push('/admin/import-syllabus');
        },
        jumpSchedule() {
            this.$router.push('/admin/import-schedule');
        },
    },
}

</script>

<style lang="scss">

</style>
