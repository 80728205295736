import {mapGetters, mapMutations} from "vuex";

export default {
    data(){
      return {
          semester_index:0,
          semester_options:{},
          search:{
              extra:{
                  S:'0',
              }
          }
      }
    },
    computed:{
        ...mapGetters('process',['which_semester']),
    },
    created() {
        this.search.extra['S'] = this.which_semester;
    },
    methods:{
        ...mapMutations('process',['setSemester']),
        loadSemesterList(){
            this.$api('Admin.Course.Semesters',{},true).then((res)=>{
                this.$set(this,"semester_options",res.data.data);
                const vm=this;
                const today = new Date().Format("yyyy-MM-dd");
                let semester = this.which_semester;
                if ( !semester || !vm.semester_options[semester]) { // 当semester==0,或者不存在
                    let max_date = '';
                    Object.getOwnPropertyNames(vm.semester_options).forEach(function(key){
                        const item = vm.semester_options[key];
                        if (item.start_date <=today && max_date <= item.start_date) {
                            semester = key;
                            max_date = item.start_date;
                        }
                    })
                    this.setSemester(semester);
                }
                this.semester_index = semester;
            });
        },
        handleChangeSemester(){
            this.setSemester( this.semester_index);
        },
        loadSemesterListForExtra(){
            this.$api('Admin.Course.Semesters',{},true).then((res)=>{
                this.$set(this,"semester_options",res.data.data);
                const vm=this;
                const today = new Date().Format("yyyy-MM-dd");
                let semester = 0; //this.which_semester;  不再记住semester
                let reload = parseInt(semester) === 0;
                if ( !semester || !vm.semester_options[semester]) { // 当semester==0,或者不存在
                    let max_date = '';
                    Object.getOwnPropertyNames(vm.semester_options).forEach(function(key){
                        const item = vm.semester_options[key];
                        if (item.start_date <=today && max_date <= item.start_date) {
                            semester = key;
                            max_date = item.start_date;
                        }
                    })
                    this.setSemester(semester);
                }
                this.search.extra['S'] = semester;
                if (reload) this.reload();
            });
        },
        handleChangeSemesterForExtra(){
            if (this.search.extra['S'] !== "0") {
                this.setSemester(this.search.extra['S']);
            }
            this.reload();
        },
    }
}
